<template>
	<div  style="width: 750px;">
	   <vue-editor ref="editor" :value="value" :editorOptions="editorSettings"   @input="changeAct"  
/>	
	  <wyu-album v-if="showAlbum" type="single" @closed="closed" @change="changePhoto"></wyu-album>
	</div>
</template>

<script>
	import { VueEditor ,Quill } from "vue2-editor";
	import { ImageDrop } from "quill-image-drop-module";
	import ImageResize from "quill-image-resize-module";
	Quill.register("modules/imageDrop", ImageDrop);
	Quill.register("modules/imageResize", ImageResize);
	import wyuAlbum from './upload/album.vue';
	export default{
		components:{
			VueEditor,
			wyuAlbum
		},
		props:{
			value:{
				type:String,
				default:'',
			},
		},
	
		data(){
			return {
				 myValue:'',
				 timer:null,
				 init:false,
				 showAlbum:false,
				 editorSettings: {
				        modules: {
				          imageDrop: true,
				          imageResize: {},
						  toolbar: {
							  container: [
								   [{ 'size': ['small', false, 'large', 'huge'] }],
									[ "bold", "italic", "underline"],
									[{ list: "ordered" }, { list: "bullet" }],
									["image"],
									 [{ 'color': [] }, { 'background': [] }],    
							  ],
							  handlers: {
								  'image': this.handleImgUpload
							  }
						  }
				        }
				}
			}
		},
		created(){
		 //  let num = 0;
		 //  this.timer = 	setInterval(()=>{
			//     num ++;
			// 	if(num > 5){
			// 		this.init = true;
			// 		clearInterval(this.timer);
			// 	}
			//     if(this.myValue == ''){
			// 		this.myValue = this.value;
			// 	}
			// },200);
		},
		methods:{
			closed(){
				this.showAlbum = false;
			},
			changePhoto(e){
				let quill = this.$refs.editor.quill;
				let length = quill.getSelection().index;
				console.log(quill);
				quill.insertEmbed(length,"image",e);
			},
			changeAct(e){
				
				this.$emit('input',e);
			},
			handleImgUpload(e){
				this.showAlbum = true;
			},
			
		}
	}
</script>

<style>
</style>